import { sizes as designSystemSizes } from '@resident-advisor/design-system'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { heightFromWidthAndAR } from 'lib/aspectRatio'
import formatImgproxyUrl from 'lib/formatImgproxyUrl'
import getConfig from 'next/config'

const {
  publicRuntimeConfig: { IMAGE_DEFAULT_SRCSETS_W },
} = getConfig()

const defaultDimensions = (IMAGE_DEFAULT_SRCSETS_W ?? '')
  .split(',')
  .map((w) => ({
    w: parseInt(w, 10),
  }))

const generateSizes = (givenSizes = {}) => {
  const defaultSize = '100vw'

  const sizes = Object.entries(givenSizes).map(
    ([key, value]) => `(min-width: ${designSystemSizes[key]}px) ${value}`
  )

  return [...sizes, defaultSize].join(', ')
}

const generateSrcSets = (
  url,
  dimensions = defaultDimensions,
  properties = {},
  crop
) => {
  const aspect = properties?.ar

  return dimensions.map((dimension) => {
    const requiredOptions = {}

    if (aspect) {
      requiredOptions.h = heightFromWidthAndAR(dimension.w, aspect)
    }

    if (!properties.rt) {
      requiredOptions.rt = 'fill'
    }

    if (!isEmpty(crop)) {
      requiredOptions.crop = getCrop(crop)
    }

    const imageOptions = {
      ...dimension,
      ...omit(properties, 'ar', 'fit'),
      ...requiredOptions,
    }

    const imageUrl = formatImgproxyUrl(url, imageOptions)

    return `${imageUrl} ${dimension.w}w`
  })
}

const getCrop = ({ width, height, x, y }) => {
  // TODO: support percentages, #26103
  // if (unit === '%') {
  //   const percentageWidth = width >= 1 ? width / 100 : width
  //   const percentageHeight = height >= 1 ? height / 100 : height
  //   return `${percentageWidth}:${percentageHeight}:nowe:${x}:${y}`
  // }

  return `${width}:${height}:nowe:${x}:${y}`
}

export { defaultDimensions, generateSizes, generateSrcSets }
