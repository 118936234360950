// Base64 encode a url and replace special characters to make it url safe
// Idea from here https://stackoverflow.com/questions/26353710/how-to-achieve-base64-url-safe-encoding-in-c

const encodeImgUrl = (url) => {
  const encodedUrl = Buffer.from(url).toString('base64')
  // replace '/' with '_'
  // replace '+' with '-'
  // remove trailing '='
  const encodedUrlSafe = encodedUrl.replace(/\//g, '_').replace(/\+/g, '-')
  // .replace(/=+$/, '') commented out for hotfix

  return encodedUrlSafe
}

export default encodeImgUrl
