import { forwardRef, PropsWithChildren } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-restricted-imports
import { Link as RALink } from '@resident-advisor/design-system'
import { UrlObject } from 'url'
import withLinkWrapper, { hrefAsPropTypes } from './withLinkWrapper'

const Link = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkProps>>(
  ({ children, href, noStyle, hoverColor, ...props }, ref) => {
    if (noStyle && typeof href === 'string')
      return (
        <a href={href} data-tracking-id={href} ref={ref} {...props}>
          {children}
        </a>
      )

    return (
      <RALink
        href={href}
        data-tracking-id={href}
        ref={ref}
        hoverColor={hoverColor}
        {...props}
      >
        {children}
      </RALink>
    )
  }
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: hrefAsPropTypes,
  noStyle: PropTypes.bool,
  hoverColor: PropTypes.string,
  maxLines: PropTypes.number,
}

Link.defaultProps = {
  noStyle: false,
}

const WrappedLink = withLinkWrapper(Link)

WrappedLink.displayName = 'Link'

WrappedLink.defaultProps = {
  noStyle: false,
}

interface LinkProps {
  href: string | UrlObject
  noStyle?: boolean
  hoverColor?: string
  variant?: string | undefined
  color?: string | undefined
  maxLines?: number | undefined
}

export default WrappedLink
