import getConfig from 'next/config'
import encodeImgUrl from './encodeImgUrl'

const { publicRuntimeConfig } = getConfig()

// dummy signature when signing disabled
const SIGNATURE = '_'

const formatImgproxyUrl = (url, options) => {
  if (!url) {
    return null
  }

  const processingOptions = []

  if (!options?.quality) processingOptions.push('quality:66')

  Object.entries(options || {}).forEach(([key, value]) => {
    if (value) {
      processingOptions.push(`${key}:${value}`)
    }
  })

  const encodedUrl = encodeImgUrl(url)

  // /%signature/%processing_options/%encoded_source_url
  const path = [SIGNATURE, ...processingOptions, encodedUrl].join('/')

  const host = publicRuntimeConfig.IMGPROXY_HOST

  return new URL(path, `https://${host}`).toString()
}

export default formatImgproxyUrl
