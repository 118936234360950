const ASPECT_RATIO = {
  '1:1': '100%',
  '16:9': '56.25%',
  'ISO-216': '141.42%',
}

const formatAspectRatio = (aspect) =>
  `1:${parseFloat(aspect.replace('%')) / 100}`

const heightFromWidthAndAspectRatio = (width, aspect) => {
  if (!aspect) {
    return 0
  }

  return Math.round((width * parseFloat(aspect.replace('%'))) / 100)
}

const heightFromWidthAndAR = (width, ar) => {
  if (!ar) {
    return 0
  }

  return Math.round(width * parseFloat(ar.replace('1:', '')))
}

export default ASPECT_RATIO
export {
  formatAspectRatio,
  heightFromWidthAndAspectRatio,
  heightFromWidthAndAR,
}
