const getUrl = (url, options) => {
  Object.entries(options).forEach(
    ([key, value]) => value && url.searchParams.set(key, value)
  )

  return url
}

const queryStringSet = (route, options) => {
  const result = getUrl(new URL(route), options)

  return result.href
}

const queryStringSetRelative = (relativeRoute, options) => {
  // need a fully qualified URL to initialise the URL object
  const baseRoute = 'https://www.example.com'
  const result = getUrl(new URL(relativeRoute, baseRoute), options)

  return `${result.pathname}${result.search}`
}

export { queryStringSetRelative }
export default queryStringSet
