import { useState, useRef, MutableRefObject } from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

// Adapted from https://github.com/jaredLunde/react-hook/tree/master/packages/resize-observer#quick-start
const useDimensions = <TElement extends HTMLElement>(): [
  MutableRefObject<TElement>,
  DOMRect
] => {
  const ref = useRef<TElement>()
  const [dimensions, setDimensions] = useState<DOMRect>()

  useIsomorphicLayoutEffect(() => {
    setDimensions(ref?.current?.getBoundingClientRect())
  }, [ref])

  useResizeObserver(ref, (entry) => setDimensions(entry.contentRect))

  return [ref, dimensions ?? ({} as DOMRect)]
}
export default useDimensions
