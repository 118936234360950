import dynamic, { LoadableOptions } from 'next/dynamic'
import {
  DefaultLoadingSpinner,
  FullScreenLoadingSpinner,
} from 'components/generic/query-result-handler'

function dynamicLoadWithSpinner<TProps = Record<string, never>>(
  importFn,
  options: DynamicLoadWithSpinnerOptions = { fullScreenLoading: false }
) {
  return dynamic<TProps>(importFn, {
    loading: options.fullScreenLoading
      ? FullScreenLoadingSpinner
      : () => <DefaultLoadingSpinner />,
    ssr: options.ssr,
  })
}

type DynamicLoadWithSpinnerOptions = LoadableOptions & {
  fullScreenLoading: boolean
}

export default dynamicLoadWithSpinner
