import { forwardRef, useMemo } from 'react'
import { Image } from '@resident-advisor/design-system'
import formatImgproxyUrl from 'lib/formatImgproxyUrl'
import { LayoutProps, SpaceProps } from 'styled-system'
import {
  defaultDimensions,
  generateSrcSets,
  generateSizes,
} from './responsiveImageUtils'

const ResponsiveImage = forwardRef<HTMLImageElement, ResponsiveImageProps>(
  (
    {
      url,
      className,
      alt,
      dimensions = defaultDimensions,
      properties = {},
      crop,
      sizes: givenSizes,
      onImageLoad,
      aspect,
      useLazy = true,
      borderRadius = 'none',
      height,
      lazyRootRef,
    },
    ref
  ) => {
    const src = formatImgproxyUrl(url, { quality: properties.quality })

    const srcSet = useMemo(
      () => generateSrcSets(url, dimensions, properties, crop),
      [url, dimensions, properties, crop]
    )

    const sizes = useMemo(() => generateSizes(givenSizes), [givenSizes])

    return (
      <Image
        ref={ref}
        url={src}
        alt={alt}
        className={className}
        aspect={aspect}
        useLazy={useLazy}
        sizes={sizes}
        srcSet={srcSet}
        borderRadius={borderRadius}
        height={height}
        onImageLoad={onImageLoad}
        lazyRootRef={lazyRootRef}
      />
    )
  }
)

ResponsiveImage.displayName = 'ResponsiveImage'

type ResponsiveImageSizes = {
  s?: string
  m?: string
  l?: string
  xl?: string
}

type ResponsiveImageDimension = {
  w: number
  h?: number
}

type ResponsiveImageProps = {
  url: string
  alt: string
  useLazy?: boolean
  dimensions?: ResponsiveImageDimension[]
  crop?: {
    unit: string
    width: string | number
    height: string | number
    x: string | number
    y: string | number
  }
  properties?: {
    rt?: string
    ar?: string
    quality?: number
    fit?: string
  }
  aspect?: SpaceProps['pb']
  sizes?: ResponsiveImageSizes
  borderRadius?: string
  height?: LayoutProps['height']
  className?: string
  onImageLoad?: () => void
  lazyRootRef?: React.RefObject<HTMLElement>
}

export type { ResponsiveImageDimension, ResponsiveImageSizes }
export default ResponsiveImage
