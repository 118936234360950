/**
 * Escape special characters in some text to make it useable inside regex
 * https://stackoverflow.com/a/3561711/10260640
 * @param {string} text - the text containing special characters
 * @returns {string} the text with special characters escaped
 */
const escapeText = (text) => {
  if (!(typeof text === 'string')) return ''
  return text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export default escapeText
